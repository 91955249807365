import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,_vm._l((_vm.cards),function(card,key){return _c(VCol,{key:key,attrs:{"cols":"12","sm":"6","lg":"3"}},[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,{staticClass:"white--text",class:card.color},[_vm._v(" "+_vm._s(card.title)+" ")]),_c(VDivider),_c(VCardText,{staticClass:"d-flex justify-end align-end"},[_c('span',{staticClass:"text-h2 pt-12"},[_vm._v(_vm._s(card.value))])])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }